import React, { useEffect, useState } from 'react';
import { DynamicForm, DynamicFormWithSelect } from '../../components/DynamicForm';
import { Box, Text } from "@chakra-ui/react";
import API from '../../services/apiRequest';
import { API_ENDPOINTS } from '../../services/apiConfig';
import { schemas } from '../../services/schemas';
import HeavyTabs from '../../components/HeavyTabs';

import DynamicAccordion, {MarkdownAccordion} from '../../components/DynamicAccordion';
//import { Accordion, AccordionButton, AccordionItem } from '@chakra-ui/react';

const CommandsContainer = () => {
    const [schemasData, setSchemasData] = useState(null);
    const [commandsData, setCommandsData] = useState(null);
    const [commandsDocs, setCommandsDocs] = useState(null);
    const [option, setOption] = useState(null);
    const [selectedCommandIndex, setSelectedCommandIndex] = useState({index: null, command_id: null});

    const getCommands = async () => {
        await API.request(API_ENDPOINTS.getAllCommands).then(res => {
            const qdata = res.data;
            setCommandsData(qdata);
            setSelectedCommandIndex({index: qdata.findIndex(item => item.id == (selectedCommandIndex.command_id || 1)), command_id: selectedCommandIndex.command_id || 1});
        });
    };

    const getCommandsDocs = async () => {
        await API.request(API_ENDPOINTS.commandsDocs).then(res => {
            const qdata = res.data;
            setCommandsDocs(qdata);
        });
    };
    const getSchemas = async () => {
        const sdata = await schemas.getSchemas();
        setSchemasData(sdata);
    };

    useEffect(() => {
        getSchemas();
        getCommands();
        // getCommandsDocs();
    }, []);

    const updateCommandsData = () => {
        getCommands();
    }

    const handleMenuClick = (value) => {
        setOption(value);
    }

    const handleEditMenuChange = (value) => {
        setSelectedCommandIndex({index: value, command_id: commandsData[value].id});
    }

    return (
        <Box>
            <HeavyTabs layout={
                [
                    // CREATE NEW COMMAND
                    {
                        label: 'Create New Command',
                        renderOn: schemasData,
                        content: (
                            <DynamicForm
                            schema={ schemasData?.CommandCreateModel }
                            endpoint={ API_ENDPOINTS.createCommand }
                            success_msg='Command created!'
                            updateCallback={ getCommands } 
                            />)
                    },

                    // EDIT COMMAND
                    {
                        label: 'Edit Command',
                        renderOn: commandsData,
                        content: (
                            <DynamicFormWithSelect
                            select_data={commandsData}
                            select_display_property='name'
                            select_text='Select Command'
                            schema={ schemasData?.CommandModel }
                            endpoint={ API_ENDPOINTS.updateCommand }
                            success_msg='Command updated!'
                            removable={ API_ENDPOINTS.deleteCommand }
                            header_text='Delete this command?'
                            submit_text='Delete'
                            usePathID={ true }
                            updateCallback={ getCommands } 
                            />)
                    },

                    // HELP
                    {
                        label: 'Help/Docs for Commands',
                        renderOn: true,
                        content: (
                            <Box>
                                {
                                <MarkdownAccordion markdown={"Nothing to see here"}/>
                                //commandsDocs && <DynamicAccordion items={commandsDocs}/>
                                }
                                
                            </Box>)
                    }
                ]
            }/>
        </Box>
    );
  };
  
  export default CommandsContainer;